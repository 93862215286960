import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div
      className="coming-soon"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "2em",
        fontWeight: "bold",
        color: "#DD1600",
        fontFamily: "Outfit-SemiBold",
      }}
    >
      <img
        src="/undraw_programming_re_kg9v.svg"
        alt="logo"
        style={{ maxWidth: "100%", height: 250 }}
      />
      <h1 style={{ marginTop: "20px", textAlign: "center" }}>Coming Soon</h1>
    </div>
  );
}

export default App;
